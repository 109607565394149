/*
These functions make sure WordPress
and Foundation play nice together.
*/

jQuery(document).ready(function($) {
	
    // Remove empty P tags created by WP inside of Accordion and Orbit
    $('.accordion p:empty, .orbit p:empty').remove();

	// // Adds Flex Video to YouTube and Vimeo Embeds
	// $('iframe[src*="youtube.com"], iframe[src*="vimeo.com"]').each(function() {
	// 	if ( jQuery(this).innerWidth() / jQuery(this).innerHeight() > 1.5 ) {
	// 	  jQuery(this).wrap("<div class='widescreen responsive-embed'/>");
	// 	} else {
	// 	  jQuery(this).wrap("<div class='responsive-embed'/>");
	// 	}
	// });

	$('#menu-main-menu').addClass('show');

	var activeModalLink;

	// Movie Overlay
	function loadYouTubeModal(evt){
	    evt.preventDefault();
	    var linkTarg = $(evt.currentTarget);
	    activeModalLink = linkTarg;

	    var url = linkTarg.attr('href');
	    var regExp = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/
	    var match = url.match(regExp);
	    $('#videoModal .video-title').text(linkTarg.attr('data-title'));
	    $('#videoModal .video-description').text(linkTarg.attr('data-description'));
	    $('#videoModal iframe').attr('src','https://www.youtube.com/embed/'+match[1]+'?rel=0&modestbranding=1&showinfo=0&autoplay=1');
	}

	function closeYoutubeModal(evt){
	    $('#videoModal iframe').attr('src','');
	    $('html, body').animate({ scrollTop: (activeModalLink.offset().top-500) }, 250);
	}

	$('.video-modal').on('click',loadYouTubeModal);
	$('#videoModal').on('closed.zf.reveal',closeYoutubeModal);


}); 